import { useCallback, useState } from 'react'
import useUnmountedRef from './useUnmountedRef'

const useSafeState = (initialValue) => {
  const unmountedRef = useUnmountedRef()
  const [state, setState] = useState(initialValue)

  const setCurrentState = useCallback((currentState) => {
    if (unmountedRef.current) return
    setState(currentState)
  })
  return [state, setCurrentState]
}

export default useSafeState
