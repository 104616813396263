import { useEffect, useState } from 'react'

export default function useSwiper() {
  const defaultSize = (process.browser && window.innerWidth) || 1200
  if (process.browser) {
    const getWindowWidth = () => window.innerWidth

    const [size, setSize] = useState(defaultSize)

    useEffect(() => {
      const onResize = () => {
        setSize(getWindowWidth)
      }
      window.addEventListener('resize', onResize)
      return () => {
        window.removeEventListener('resize', onResize)
      }
    })
    return size
  }
  return defaultSize
}
