import { createSlice } from '@reduxjs/toolkit'
// import { isAppSupportedNetworkId } from '_src/utils/chain'
// state数据
let initialState = {
  theme: 'dark',
  lng: '',
  isLogin: false,
  userInfo:{}
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lng = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    // 用户信息
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
      // console.log('state',state.userInfo)
    },
    // setConnected: (state, action) => {
    //   state.connected = action.payload
    // },
    // setAddress: (state, action) => {
    //   state.address = action.payload
    // },
    // setIsSupportNetworkAction: (state, action) => {
    //   state.isSupportNetworkInfo = action.payload
    // },
    // setChainId: (state, action) => {
    //   if (action.payload) {
    //     const chainId = action.payload
    //     state.chainId = chainId
    //   }
    // },
    // setNetworkId: (state, action) => {
    //   if (action.payload) {
    //     const networkId = action.payload
    //     state.networkId = networkId
    //   }
    // },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload
    },
    clearGlobalStore: (state) => {
      // state.connected = false
      // state.chainId = ''
      // state.address = ''
      // state.networkId = ''
      // state.isSupportNetworkInfo = false,
      state.isLogin = false
    },
  },

  extraReducers: (builder) => {
    builder
      // .addCase(loginAsync.pending, (state) => {
      //   state.loginLoading = true
      // })
      // .addCase(loginAsync.fulfilled, (state, action) => {
      //   if (action.payload) {
      //     const { useData } = action.payload
      //     state.userData = useData || {} // 账户信息
      //     // state.balanceList = balanceList || {}; // 账户余额
      //   }
      //   state.loginLoading = false
      // })
      // .addCase(loginAsync.rejected, (state) => {
      //   state.userData = {} // 账户信息
      //   // state.balanceList = {}; // 账户余额
      //   state.loginLoading = false
      // })
      // .addCase(getBalanceList.pending, (state) => {
      //   state.balanceListLoading = true;
      // })
      // .addCase(getBalanceList.fulfilled, (state, action) => {
      //   if (action.payload) {
      //     state.balanceList = action.payload; // 账户余额
      //   }
      //   state.balanceListLoading = false;
      // })
      // .addCase(getBalanceList.rejected, (state) => {
      //   state.balanceList = {}; // 账户余额
      //   state.balanceListLoading = false;
      // })
      .addMatcher(
        (action) => action.type.endsWith('/setNetworkId')
        // (state, action) => {
        // 不支持网络更改
        // if (isAppSupportedNetworkId(action.payload)) {
        //   state.isSupportNetworkInfo = false
        // } else {
        //   state.isSupportNetworkInfo = true
        // }
        // }
      )
  },
})

export const {
  setLanguage,
  setUserInfo,
  // setConnected,
  // setIsSupportNetworkAction,
  // setAddress,
  // setChainId,
  // setNetworkId,
  clearGlobalStore,
  setIsLogin,
} = globalSlice.actions

export default globalSlice.reducer
