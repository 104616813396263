export const firepowerLightTheme = {
  '--firepower-font-c-1': '#333',
  '--firepower-font-c-2': '#fff',
  '--firepower-font-c-3': '#f8f8f8',
  '--firepower-bgc-1': '#333',
  '--firepower-bgc-2': '#fff',
  '--firepower-bgc-3': '#f8f8f8',
  '--firepower-bgc-4': '#fdf6ec',
  '--firepower-bgc-5': '#f5f9fa',
  '--firepower-border-c-1': '#333',
}
export const firepowerDarkTheme = {
  '--firepower-font-c-1': '#333',
  '--firepower-font-c-2': '#fff',
  '--firepower-font-c-3': '#f8f8f8',
  '--firepower-bgc-1': '#333',
  '--firepower-bgc-2': '#fff',
  '--firepower-bgc-3': '#f8f8f8',
  '--firepower-bgc-4': '#fdf6ec',
  '--firepower-bgc-5': '#f5f9fa',
  '--firepower-border-c-1': '#333',
}
